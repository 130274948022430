import React from 'react'
import Layout from '../../../components/layouts/Layout'
import BreadcrumbBlock from '../../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Flex, Tabs, TabsProps } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { Home2 } from 'iconsax-react'
import '../Styles/Setting.scss'

interface Props {
  current?: string
  children?: React.ReactNode
  matchHeight?: Boolean
}

const items: TabsProps['items'] = [
  { key: 'device', label: 'Device', },
  { key: 'shift', label: 'Shift', },
  { key: 'match-rules', label: 'Match rules', },
  { key: 'role-permission', label: 'Role & permission', },
  { key: 'user-list', label: 'User list', },
  { key: 'my-account', label: 'My account', },
  { key: 'payment-method', label: 'Payment method', },
];

const SettingLayout:React.FC<Props> = (props) => {
  const { current, children, matchHeight = true } = props

  const navigate = useNavigate()

  const onTabChange = (activeKey: string) => {
    navigate(`/setting/${activeKey}`)
  }

  return (
    <Layout
      className={matchHeight ? 'match-height' : ''}
      contentClassName="setting-layout"
    >
      <BreadcrumbBlock
        items={[
          { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
          { title: 'Setting', },
        ]}
        title='Setting'
        description=''
      />

      <div className="tab-rps">
        <Tabs
          defaultActiveKey="device"
          activeKey={current}
          items={items}
          className='rs-tabs rs-tabs--no-tab-content'
          popupClassName='rs-tabs-dropdown'
          onChange={onTabChange}
        />
      </div>

      { children }
    </Layout>
  )
}

export default SettingLayout
