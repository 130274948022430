import React, { useEffect, useState } from 'react'
import './Results.scss'
import { Button } from 'antd'
import { Send2 } from 'iconsax-react'
import GameEmptyResult from './EmptyResult'
import GamePlayer from './Player'
import RandomInputItem from './RandomInputItemV2'
import { CloseOutlined } from '@ant-design/icons'

interface Props {
  title?: string,
  userAvatar?: string,
  userName?: string,
  userId?: string,
  type?: string,
  empty?: boolean,
  className?: string,
  onSendSMS?: () => void,
  cfList?: any
  onClose?: () => void,
}

const Results: React.FC<Props> = (props) => {
  const { title, type = 'lucky-game', userAvatar ='/images/avatar/avatar1-40x40.png', userName = 'Jane Cooper', userId = 'G321', empty = true, className = '', onSendSMS, cfList, onClose } = props

  const [listCf, setListCf] = useState([] as any)
  const [sum, setSum] = useState(0)
  useEffect(()=>{
    const temp = [] as any
    let count = 0
    cfList.map((val: any) => {
      if (val.count > 0) {
        temp.push(val)
        count += val.count
      }
      return true
    })
    setSum(count)
    setListCf(temp)
  }, [cfList])
  const sendSMS = () => {
    if (onSendSMS) {
      onSendSMS()
    }
  }

  return (
    <>
      <div className={`game-results ${className}`}>
        <div className="game-results__header">
          <span>{ title } {sum > 0 && <b>{sum}</b>}</span>
          <CloseOutlined
            className='neutral-gray-500 mis-auto'
            onClick={onClose}
          />
        </div>

        <div className={`game-results__content v2 ${type}`}>
          {
            listCf.length === 0
              ?
                <GameEmptyResult />
              :
                type === 'lucky-game'
                  ?
                    <GamePlayer
                      id={userId}
                      avatar={userAvatar}
                      name={userName}
                    />
                  :
                    <>
                      {
                        listCf.map((item: any) => (
                          <RandomInputItem key={item.id} text={`${item.text} [ ${item.count} ]`} showDelete={false} />
                        ))
                      }
                    </>
          }
        </div>

        {
          (type === 'lucky-game' && !empty) &&
            <div className="game-results__footer">
              <Button
                icon={<Send2 size="20" variant="Bulk"/>}
                type='primary'
                block
                className='rs-button primary-outlined size-sm'
                onClick={sendSMS}
              >Send message</Button>
            </div>
        }
      </div>

      <div
        className={`game-results-backdrop ${className}`}
        onClick={onClose}
      />
    </>
  )
}

export default Results
