import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, Input, Tabs, notification } from 'antd'
import { Code1, DirectSend, Home2, Lock, ShieldTick } from 'iconsax-react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { mixPostV1 } from '../../services/mixin'

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const isSeller = user.role_id === 3

const AccountSetting:React.FC = () => {
  // const [loading, setLoading] = useState(false)
  const formRef = React.useRef<any>(null)
  const formPasswordRef = React.useRef<any>(null)
  const formPasscodeRef = React.useRef<any>(null)
  const [passwordLengthAccept, setPasswordLengthAccept] = useState(false)
  const [passwordFormLoading, setPasswordFormLoading] = useState(false)
  const [passcodeFormLoading, setPasscodeFormLoading] = useState(false)

  const setFormValue = () => {
    const email = JSON.parse(localStorage.getItem('user') || '{}')?.email
    if (formRef.current) {
      formRef.current.setFieldValue('email', email)
    }
  }
  useEffect(() => {
    setFormValue()
  }, [])

  const onPasswordFinish = async (values: any) => {
    setPasswordFormLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/editMyAccountPassword.php', values)
      if (res.status) {
        openNotification('success', 'Password updated!')
        setPasswordFormLoading(false)

        if (formPasswordRef && formPasswordRef.current) {
          formPasswordRef.current.resetFields()
          setPasswordLengthAccept(false)
        }
      } else {
        setPasswordFormLoading(false)
        openNotification('error', res.message)
      }
    } catch (error) {
      setPasswordFormLoading(false)
      openNotification('error', 'Oops, something went wrong!')
    }
  }

  const onPasscodeFinish = async (values: any) => {
    setPasscodeFormLoading(true)

    try {
      const res = await mixPostV1('/api/webAdmin/editMyAccountPassCode.php', values)

      if (res.status) {
        openNotification('success', 'Passcode updated!')
        setPasscodeFormLoading(false)

        if (formPasscodeRef && formPasscodeRef.current) {
          formPasscodeRef.current.resetFields()
        }
      } else {
        setPasscodeFormLoading(false)
        openNotification('error', res.message)
      }
    } catch (error) {
      setPasscodeFormLoading(false)
      openNotification('error', 'Oops, something went wrong!')
    }
  }

  const onValuesChange = (changeValues: any, allValues: any) => {
    const { newPassword } = allValues

    if (newPassword) {
      if (newPassword.length >= 8 && newPassword.length <= 15) {
        setPasswordLengthAccept(true)
      } else {
        setPasswordLengthAccept(false)
      }
    } else {
      setPasswordLengthAccept(false)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Account updated!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <>
      { contextHolder }

      <Layout
        className='match-height'
        contentClassName="setting-layout"
      >
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Account Setting', },
          ]}
          title='Account Setting'
          description=''
        />

        <Tabs
          defaultActiveKey="device"
          activeKey={'1'}
          items={[{ key: '1', label: 'My account' }]}
          className='rs-tabs rs-tabs--no-tab-content'
        />

        <div className="setting-content setting-content--update setting-my-account">
          <div className="mw-596">
            <div className='title-12 text-idle'>Edit and click <span className='font-bold'>Update</span> if you want to change anything</div>
            <Form
              ref={formRef}
              autoComplete="off"
              className="rs-form"
              layout="vertical"
            >
              <Form.Item
                name="email"
                label="Email"
              >
                <Input
                  placeholder="Email"
                  className="rs-input"
                  // disabled={true}
                  readOnly
                  prefix={<DirectSend size={18} variant="Bulk" />}
                />
              </Form.Item>
            </Form>
          </div>

          <Form
            ref={formPasswordRef}
            autoComplete="off"
            className="rs-form form-password-passcode"
            layout="vertical"
            onFinish={onPasswordFinish}
            onValuesChange={onValuesChange}
          >
            <Flex gap={12}>
              <Form.Item
                name="oldPassword"
                label="Old password"
                rules={[
                  {required: true, message: 'This field must be filled!'}
                ]}
                className='w-full'
              >
                <Input
                  placeholder="Old password"
                  className="rs-input"
                  type='password'
                  prefix={<Lock size={18} variant="Bulk" />}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New password"
                rules={[
                  {required: true, message: 'This field must be filled!'},
                  { min: 8, message: 'Password must be between 8 and 15 characters in length.' },
                  { max: 15, message: 'Password must be between 8 and 15 characters in length.' }
                ]}
                className='w-full'
              >
                <Input
                  placeholder="New password"
                  className="rs-input"
                  type='password'
                  prefix={<Lock size={18} variant="Bulk" />}
                />
              </Form.Item>
              <Form.Item className="ctrl-btn" label="&nbsp;">
                <Button
                  className='rs-button button-update'
                  type='primary'
                  loading={passwordFormLoading}
                  onClick={() => formPasswordRef.current.submit()}
                >Update</Button>
              </Form.Item>
            </Flex>
          </Form>

          <div className="line-600"></div>

          {
            !isSeller &&
              <Form
                ref={formPasscodeRef}
                autoComplete="off"
                className="rs-form form-password-passcode"
                layout="vertical"
                onFinish={onPasscodeFinish}
              >
                <Flex gap={12}>
                  <Form.Item
                    name="oldPasscode"
                    label="Old passcode"
                    rules={[
                      {required: true, message: 'This field must be filled!'},
                      {
                        type: 'number',
                        message: 'Please type numbers.',
                        transform(value) {
                          return Number(value)
                        },
                      },
                    ]}
                    className='w-full'
                  >
                    <Input
                      placeholder="Old passcode"
                      className="rs-input"
                      prefix={<Code1 size={18} variant="Bulk" />}
                      count={{
                        show: false,
                        max: 4,
                        strategy: (txt) => txt.length,
                        exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="newPasscode"
                    label="New passcode"
                    rules={[
                      {required: true, message: 'This field must be filled!'},
                      {
                        type: 'number',
                        message: 'Please type numbers.',
                        transform(value) {
                          return Number(value)
                        },
                      },
                    ]}
                    className='w-full'
                  >
                    <Input
                      placeholder="New passcode"
                      className="rs-input"
                      prefix={<Code1 size={18} variant="Bulk" />}
                      count={{
                        show: false,
                        max: 4,
                        strategy: (txt) => txt.length,
                        exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
                      }}
                    />
                  </Form.Item>

                  <Form.Item className="ctrl-btn" label="&nbsp;">
                    <Button
                      className='rs-button button-update'
                      type='primary'
                      loading={passcodeFormLoading}
                      onClick={() => formPasscodeRef.current.submit()}
                    >Update</Button>
                  </Form.Item>
                </Flex>
              </Form>
          }

          <div className="flex flex-vertical gap-12 mt-12-600">
            <div className="flex gap-8">
              <ShieldTick size={20} variant='Bulk' className={passwordLengthAccept ? 'text-success' : 'text-idle'} />
              <span className='text-idle'>Password must be between 8 and 15 characters in length</span>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default AccountSetting
