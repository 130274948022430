import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageMenu from '../PageElements/Menu'
// import Header from './components/PageElements/Header'
import './Layout.scss'
import { Avatar, Button, Dropdown, Flex, Menu, message } from 'antd'
import { ArrowSquareDown, HambergerMenu, LogoutCurve } from 'iconsax-react'
import type { MenuProps } from 'antd'
import { mixPostV1 } from '../../services/mixin'
import { CloseOutlined } from '@ant-design/icons'

interface LayoutProps {
  className?: string
  contentClassName?: string
  children?: React.ReactNode
}

const defaultAvatar = <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'> <g opacity='0.6'> <circle cx='19.667' cy='19.667' r='15' fill='#D9D9D9'/> <path d='M19.9997 23.3333C23.6816 23.3333 26.6663 20.3486 26.6663 16.6667C26.6663 12.9848 23.6816 10 19.9997 10C16.3178 10 13.333 12.9848 13.333 16.6667C13.333 20.3486 16.3178 23.3333 19.9997 23.3333Z' fill='white'/> <path d='M9.47852 30.6755C11.36 27.3181 15.3641 25 20.0004 25C24.4463 25 28.3109 27.1315 30.2798 30.2671C27.5649 32.9852 23.8124 34.6667 19.6671 34.6667C15.7336 34.6667 12.1538 33.1526 9.47852 30.6755Z' fill='currentColor'/> </g> </svg>

const Layout: React.FC<LayoutProps> = ({ className = '', contentClassName = '', children }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('storeId')
    localStorage.removeItem('storeTypeId')
    localStorage.removeItem('stores')
    window.location.href = '/login'
  }

  // GET STORES
  // const [stores, setStores] = useState<MenuProps['items']>()
  const stores = JSON.parse(localStorage.getItem('stores') || '[]')
  // const [stores, setStores] = useState([] as any)
  const [currentStoreId, setCurrentStoreId] = useState(localStorage.getItem('storeId') || '')

  const currentStoreName: any = stores.find((item: any) => item.store_id === Number(currentStoreId))?.store_name

  // let wait = false
  // const getStores = async () => {
  //   if (wait === true) {
  //     return
  //   }
  //   wait = true
  //   try {
  //     const res = await mixPostV1('/api/webAdmin/gComboStoreByOwner.php', {owner_id: user.id})
  //     wait = false
  //     if (res.status === 1) {
  //       const result: MenuProps['items'] = []
  //       res.data.map((item: any) => result.push({
  //         ...item,
  //         key: item.store_id.toString(),
  //         label: item.store_name,
  //       }))
  //       setStores(result)

  //       if (result.length === 1) {
  //         const storeId: any = result[0]?.key
  //         setCurrentStoreId(storeId.toString())
  //         localStorage.setItem('storeId', storeId.toString())
  //       }
  //     }
  //   } catch (error) { console.log(error) }
  // }
  const menu = (
    <Menu
      selectedKeys={[currentStoreId]}
      className='store-select-menu'
    >
      {stores?.map((store: any) => (
        <Menu.Item key={store?.key} onClick={() => choseStore(store?.key, store?.type_id)}>{ store?.label }</Menu.Item>
      ))}
    </Menu>
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => { getStores() }, [])

  const [messageApi, contextHolder] = message.useMessage();
  const choseStore = async (val: number, typeId: any) => {
    if (Number(currentStoreId) === Number(val)) return

    const param = {
      owner_id: user.id,
      store_id: val,
    }
    setCurrentStoreId(val.toString())
    const res = await mixPostV1('/api/webAdmin/createTokenAdmin.php', param)
    if (res.status === 1) {
      localStorage.setItem('token', res.token)
      localStorage.setItem('storeId', val.toString())
      localStorage.setItem('storeTypeId', typeId.toString())
      window.location.reload()
    } else {
      messageApi.open({
        type: 'error',
        content: res.message,
      });
    }
  }
  // END GET STORES

  // TRIGGER WIDGET
  const [showWidget, setShowWidget] = useState(false)
  const triggerWidget = () => {
    setShowWidget(!showWidget)
  }
  // END TRIGGER WIDGET

  return (
    <>
      { contextHolder }

      <div className={`app ${className}`}>
        <div className={`app-widget ${showWidget ? 'app-widget-show' : ''}`}>
          <Link to={'/'} className="app-widget__logo">
            <img src="/images/logo.svg" alt="CMDTech" className="default-logo"/>
            <img src="/images/favicon.svg" alt="CMDTech" className="collapsed-logo"/>
          </Link>

          {
            (user.role_id === 1 || user.role_id === 2) && (
              <div className="store-select">
                <div className="store-select-selected">
                  <span>My store: { currentStoreName }</span>
                  {
                    (stores && stores?.length > 0) &&
                      <Dropdown dropdownRender={() => (menu)} placement="bottomRight" arrow>
                        <ArrowSquareDown variant="Bulk" />
                      </Dropdown>
                  }
                </div>
              </div>
            )
          }

          {
            user.role_id === 4 && (
              <div className="store-select">
                <div className="store-select-selected">
                  <span>My store: { user.store_name }</span>
                </div>
              </div>
            )
          }

          <PageMenu />

          <Flex align='center' gap="small" className="app-widget__footer">
            <Avatar shape="square" icon={defaultAvatar} className="user-avatar" />

            <Flex vertical gap={4}>
              <span className="name">{user.owner_name}</span>
              <span className="role fsz-12 lh-18">{user.role_name}</span>
            </Flex>

            <LogoutCurve onClick={logout} className="logout" variant="Bulk"/>
          </Flex>

          <Button
            icon={<CloseOutlined />}
            className='rs-button app-widget__trigger'
            onClick={triggerWidget}
          />
        </div>

        <div className="app-header">
          <Link to={'/'} className="app-header__logo">
            <img src="/images/logo.png" alt="CMDTech" className="default-logo"/>
          </Link>
          <Button
            icon={<HambergerMenu size="28"/>}
            className='rs-button'
            onClick={triggerWidget}
          />
        </div>

        <div className='app-main'>
          {/* <Header /> */}
          <div className={`app-content ${contentClassName}`}>
            {children}
          </div>
        </div>

        <div
          className={`app-backdrop ${showWidget ? 'app-widget-show' : ''}`}
          onClick={triggerWidget}
        />
      </div>
    </>
  )
}

export default Layout
