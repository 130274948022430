import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Button, Flex, Input, notification, Pagination, PaginationProps, Spin } from 'antd'
import { DocumentDownload, Gameboy, Home2, SearchZoomIn1 } from 'iconsax-react'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import CreateNewPopup from './CreateNewPopup'
import DeleteModal from '../../components/Modal/DeleteModal'
import './style.scss'
import EditPopup from './EditPopupMachine'
// import Data from './machine.json'
import { mixPostV1 } from '../../services/mixin'
import * as XLSX from 'xlsx'

const drag = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6Z" stroke="#E6E6E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13Z" stroke="#E6E6E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M9 20C9.55228 20 10 19.5523 10 19C10 18.4477 9.55228 18 9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20Z" stroke="#E6E6E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M16 6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4C15.4477 4 15 4.44772 15 5C15 5.55228 15.4477 6 16 6Z" stroke="#E6E6E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z" stroke="#E6E6E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M16 20C16.5523 20 17 19.5523 17 19C17 18.4477 16.5523 18 16 18C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20Z" stroke="#E6E6E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>

const pencil = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 20H21" stroke="#E6E6E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z" stroke="#E6E6E6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>

const trash = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#FD6A6A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>

const customPrevNext: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Flex align='center' gap={8}>
        <ArrowLeftOutlined />
        <span>Previous</span>
      </Flex>
    )
  }
  if (type === 'next') {
    return (
      <Flex align='center' gap={8}>
        <span>Next</span>
        <ArrowRightOutlined />
      </Flex>
    )
  }
  return originalElement
}

const Machine: React.FC = () => {
  const [showCreateNewPopup, setShowCreateNewPopup] = useState(false)
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [showDeleteStaffPopup, setShowDeletePopup] = useState(false)
  const [listData, setListData] = useState([
    { id: 1, machine_id: '', machine_name: '', sorting_index: '' },
  ])
  const [loadingItems, setLoadingItems] = useState(false)

  // DRAG AND DROP | MACHINE SORTING
  const handleDragStart = (e: React.DragEvent<HTMLLIElement>, id: number) => {
    e.dataTransfer.setData('id', id.toString());
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLLIElement>, index: number) => {
    const oldItems = listData
    const simpleItems = [] as any

    const id = e.dataTransfer.getData('id')
    const draggedProduct:any = listData.find((product) => product.id === parseInt(id))
    const newProducts = listData.filter((product) => product.id !== parseInt(id))
    newProducts.splice(index, 0, draggedProduct!)

    const dropPos = newProducts.map(item => item.id).indexOf(Number(id))
    const productOnDropPos = oldItems[dropPos]

    simpleItems.push(
      {
        id: draggedProduct?.id,
        sorting_index: productOnDropPos?.sorting_index,
        name: draggedProduct?.machine_name
      },
      {
        id: productOnDropPos?.id,
        sorting_index: draggedProduct?.sorting_index,
        name: productOnDropPos?.machine_name
      },
    )

    sortMachine(simpleItems)
  };

  const sortMachine = async (list: any) => {
    setLoadingItems(true)

    const params = {
      array_machine: JSON.stringify(list)
    }
    const res = await mixPostV1('/api/webAdmin/swapSortMachine.php', params)
    if (res.status === 1) {
      getItems()
    }
  }
  // --- END DRAG AND DROP | MACHINE SORTING

  // GET MACHINES
  const [items, setItems] = useState({
    list: [],
    total: 0,
  })

  const [params, setParams] = useState({
    limit: 20,
    page_number: 1,
    searchKey: '',
  })

  const getItems = async () => {
    setLoadingItems(true)
    const res = await mixPostV1('/api/webAdmin/gListMachineByStore.php', params)

    if (res.status === 1) {
      setItems({
        ...items,
        list: res.data,
        total: res.total,
      })
      setListData(res.data)
      setLoadingItems(false)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onPageChange = (page: number) => {
    setParams({
      ...params,
      page_number: page,
    })
  }

  const onPageSizeChange = (current: number, size: number) => {
    setParams({
      ...params,
      limit: size,
      page_number: current,
    })
  }
  // END GET MACHINES

  // SEARCH
  const [searchTimeout, setSearchTimeout] = useState<any>(null)
  const onSearch = (e: any)  => {
    setParams({
      ...params,
      page_number: 1,
      searchKey: e.target.value
    })
  }
  const onChange = (e: any) => {
    clearTimeout(searchTimeout)
    const timeout = setTimeout(() => {
      onSearch(e)
    }, 250)
    setSearchTimeout(timeout)
  }
  // END SEARCH

  // EXPORT EXCEL
  const getItemsByExcel = async () => {
    const customParams = {
      ...params,
      page_number: 1,
      limit: 10000,
    }

    try {
      const res = await mixPostV1('/api/webAdmin/gListMachineByStore.php', customParams)

      if (res.status) {
        if (res.data.length > 0) {
          formattedDataExcel(res.data)
        } else {
          openNotification('error', 'There is no data to export!')
        }
      } else {
        openNotification('error', 'Oops, something went wrong!')
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  }
  const formattedDataExcel = (data: any) => {
    const formattedData = data.map((item: any) => {
      return {
        'Machine ID': item.machine_id,
        'Machine Name': item.machine_name,
        'Position': item.sorting_index,
      }
    })
    exportExcel(formattedData)
  }
  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData)
      const workbook: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách')

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' })
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.download = fileName
        link.click()
      }

      saveAsExcel(excelBuffer, `${"Machine list"}.xlsx`)
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error)
      return false // Export failed
    }
  }
  // END EXPORT EXCEL

  // CHECK ROLE = 'USER'
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const permission = user.permission
  const machineRole = permission ? permission.find((item: any) => item.function_id === 2) : null

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Request success!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <Layout contentClassName="machine-content">
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Machine', },
          ]}
          title='Machine'
          description=''
        />

        <div className="table-filter-block">
          <div className="left">
            <div className="title-filter t-fz-16">Machine list</div>
            <Input
              placeholder="Search by name, id"
              allowClear
              prefix={<SearchZoomIn1 size={20} variant="Bulk" />}
              // className="rs-input t-input-ctrl mw-320px"
              className="rs-input table-filter-block__search"
              onChange={(e) => onChange(e)}
              onPressEnter={(e) => onSearch(e)}
            />
          </div>

          <div className="right">
            <Button
              icon={<DocumentDownload size={20} variant="Bulk" />}
              className="rs-button"
              onClick={getItemsByExcel}
            >Export</Button>

            <Button
              icon={<Gameboy size={20} variant="Bulk" />}
              className="rs-button"
              disabled={machineRole && machineRole.is_create === 0}
              onClick={() => setShowCreateNewPopup(true)}
            >Add machine</Button>
          </div>
        </div>

        <div className="content-block">
          <Spin spinning={loadingItems}>
            <ul>
              {/* Lặp qua danh sách với mỗi phần tử là một Draggable */}
              {
                listData.length > 0 && listData.map((item, index) => (
                  <li
                    key={item.id}
                    draggable={!machineRole || (machineRole && machineRole.is_edit === 1)}
                    onDragStart={(e) => handleDragStart(e, item.id)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e, index)}
                    className="one-item"
                  >
                    <div className="bg-img">
                      <img src="/images/gameboy.png" alt="img" />
                    </div>
                    <div className="list-ctrl">
                      <Button
                        icon={drag}
                        className="rs-button cursor-move"
                        disabled={machineRole && machineRole.is_edit === 0}
                      />
                      <Button
                        icon={trash}
                        className="rs-button"
                        disabled={machineRole && machineRole.is_delete === 0}
                        onClick={() => {
                          setSelectedId(item.id)
                          setShowDeletePopup(true)
                        }}
                      />
                      <Button
                        icon={pencil}
                        className="rs-button edit"
                        disabled={machineRole && machineRole.is_edit === 0}
                        onClick={() => {
                          setSelectedId(item.id)
                          setShowEditPopup(true)
                        }}
                      />
                    </div>
                    <div className="footer">
                      <div className="name cl-E5E5E5">{item.machine_name}</div>
                      <div className="id-order-block">
                        <div className="idmachine cl-999999">ID {item.machine_id}</div>
                        <div className="tag">{item.sorting_index}</div>
                      </div>
                    </div>
                  </li>
              ))}
            </ul>
          </Spin>
        </div>

        {/* <div className="t-Pagination"> */}
          <Pagination
            className='rs-pagination'
            defaultCurrent={1}
            defaultPageSize={params.limit}
            current={params.page_number}
            total={items.total}
            style={{ marginBlockStart: 'auto' }}
            itemRender={customPrevNext}
            onChange={(e) => onPageChange(e)}
            onShowSizeChange={(current, size) => onPageSizeChange(current, size)}
          />
        {/* </div> */}

        <CreateNewPopup
          isShow={showCreateNewPopup}
          onPopupClose={() => setShowCreateNewPopup(false)}
          onSuccess={getItems}
        />

        <EditPopup
          id={selectedId}
          isShow={showEditPopup}
          onPopupClose={() => setShowEditPopup(false)}
          onSuccess={getItems}
        />

        <DeleteModal
          isShow={showDeleteStaffPopup}
          content='Are you sure you want to delete this program machine?'
          message='Machine deleted!'
          deleteApi='/api/webAdmin/deleteMachineById.php'
          deleteId={selectedId}
          onModalClose={() => setShowDeletePopup(false)}
          onModalSubmit={() => {
            getItems()
            setShowDeletePopup(false)
          }}
        />
      </Layout>
    </>
  )
}

export default Machine
