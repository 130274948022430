import React, { useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Flex } from 'antd'
import { Home2 } from 'iconsax-react'
import GameLayout from './Components/GameLayout'
import Players from './Components/PlayersV2'
import GameBar from './Components/GameV2'
import Results from './Components/ResultsV2'
import DeleteModal from '../../components/Modal/DeleteModal'

const randomItems = [
  // { id: 1, text: 'Brooklyn Simmons' },
  // { id: 2, text: 'Brooklyn Simmons' },
  // { id: 3, text: 'Brooklyn Simmons' },
  // { id: 4, text: 'Brooklyn Simmons' },
  // { id: 5, text: 'Brooklyn Simmons' },
  // { id: 6, text: 'Brooklyn Simmons' },
] as any

const RandomGame: React.FC = () => {
  const [gamePlaying, setGamePlaying] = useState(false)
  const [gameConfirm, setGameConfirm] = useState(false)
  const [showCancelPrizeModal, setShowCancelPrizeModal] = useState(false)
  const [players, setPlayers] = useState(randomItems)
  const [showPlayerListOnMobile, setShowPlayerListOnMobile] = useState(false)
  const [showResultListOnMobile, setShowResultListOnMobile] = useState(false)

  const addRandomItem = () => {
    const id = Date.now()
    setPlayers([
      ...players,
      { id, text: '', count: 0 }
    ])
  }

  const changeCf = (e: any) => {
    const temp = [] as any
    players.map((val: any) => {
      const item = val
      if (val.id === e.id && val.text) {
        item.count += 1
      }
      temp.push(item)
      return true
    })
    setPlayers([...temp])
  }

  return (
    <>

      <Layout
        className='match-height'
        contentClassName="random-game-content"
      >
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Store Game', },
            { title: 'Random Game', },
          ]}
          title='Random Game'
          description=''
        />

        <GameLayout
          players={
            <Players
              type='random-game'
              title='Inputs'
              list={players}
              className={`${showPlayerListOnMobile ? 'show' : ''}`}
              onRemove={(id) => setPlayers(players.filter((item: any) => item.id !== id))}
              onRemoveAll={() => setPlayers([])}
              onAddPlayer={addRandomItem}
              onChange={(e: any) => {
                setPlayers(e)
              }}
              onClose={() => setShowPlayerListOnMobile(false)}
            />
          }
          game={<GameBar
            type='random-game'
            isPlaying={gamePlaying}
            players={players}
            onPlay={() => { setGameConfirm(false); setGamePlaying(true) }}
            onConfirm={(e: any) => { changeCf(e); setGamePlaying(false) }}
            onCancel={() => setShowCancelPrizeModal(true) }
            onPlayerClick={() => setShowPlayerListOnMobile(true)}
            onResultClick={() => setShowResultListOnMobile(true)}
          />}
          results={
            <Results
              title='Result'
              type='random-game'
              cfList={players}
              empty={!gameConfirm}
              className={`${showResultListOnMobile ? 'show' : ''}`}
              onClose={() => setShowResultListOnMobile(false)}
            />
          }
        />
      </Layout>

      {/* MODAL CANCEL PRIZE */}
      <DeleteModal
        isShow={showCancelPrizeModal}
        content={`Are you sure you want to \ncancel this prize?`}
        message='Prize is canceled!'
        yesText='Yes, cancel prize'
        onModalClose={() => setShowCancelPrizeModal(false)}
        onModalSubmit={() => {
          setShowCancelPrizeModal(false)
          setGameConfirm(false)
          setGamePlaying(false)
        }}
      />
    </>
  )
}

export default RandomGame
